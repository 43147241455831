#root {
  width: 100%; /* Spans the whole document */
  overflow-x: hidden; /* Hides overflow instead of displaying it */
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
  }
}

.container.navbar-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.collapse.navbar-collapse {
  margin-left: auto;
  margin-right: auto;
}

.logo {
  margin-left: 15px;
}

@keyframes slide-down {
  0% {
    transform: translateY(30%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes slide-down {
  0% {
    -webkit-transform: translateY(30%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(-30%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes slide-up {
  0% {
    -webkit-transform: translateY(-30%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-right {
  0% {
    transform: translate(100%);
    opacity: 0;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translate(100%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(0);
    opacity: 1;
  }
}

@keyframes slide-in-left {
  0% {
    transform: translate(-100%);
    opacity: 0;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
}
@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translate(-100%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(0);
    opacity: 1;
  }
}

.from-down {
  animation: slide-down 1s forwards;
  -webkit-animation: slide-down 1s forwards;
}

.from-up {
  animation: slide-up 1s forwards;
  -webkit-animation: slide-up 1s forwards;
}

.from-right {
  animation: slide-in-right 1s forwards;
  -webkit-animation: slide-in-right 1s forwards;
}

.from-left {
  animation: slide-in-left 1s forwards;
  -webkit-animation: slide-in-left 1s forwards;
}

.delay-5 {
  animation-duration: 1.5s;
}
.delay-10 {
  animation-duration: 1s;
}
.delay-15 {
  animation-duration: 1.5s;
}

.delay-20 {
  animation-duration: 2s;
}

.delay-25 {
  animation-duration: 2.5s;
}

.none {
  visibility: hidden;
}

@media (max-width: 768px) {
  .container.navbar-container {
    display: inherit;
  }
  .container > .navbar-header,
  .container-fluid > .navbar-header,
  .container > .navbar-collapse,
  .container-fluid > .navbar-collapse {
    padding-bottom: 10px;
  }

  .container.navbar-container .navbar-header {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
  }
}

@media (max-width: 600px) {
  .intro-text h1 {
    font-size: 36px;
  }
}
