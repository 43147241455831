.tech-container {
  padding-top: 50px;
  margin-right: auto;
  margin-left: auto;
}

.focus {
  transition: transform 0.3s;
}

.card {
  margin-bottom: 1.625rem;
}

.focus:hover {
  transform: scale(1.05);
}

.card-title {
  font-size: 2em;
  font-weight: 700;
  /* color: #f07e3e; */
  background: linear-gradient(
    0deg,
    rgba(30, 96, 171, 0.943) 50%,
    rgba(120, 58, 42, 0.9206276260504201) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(30, 96, 171, 0.943) 50%,
    rgba(120, 58, 42, 0.9206276260504201) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.card-deck {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-flow: nowrap;
  margin: 0;
  margin-bottom: 10px;
}
.card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px 0;
}
.card-deck .card {
}

/* .card-title.h5::before {
  position: absolute;
  content: "";
  background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
  height: 2px;
  width: 100%;
  top: 40;
  left: 0;
} */

.card-text {
  margin: 2.5px 5px;
  font-size: 16px;
  font-weight: 500;
}

.card-text img {
  margin-right: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

@media only screen and (max-width: 786px) {
  .card-deck {
    /* flex-flow: column;
    justify-content: center; */
    margin: 0 20px;
    flex-direction: column;
  }
  .card-deck {
    align-items: center;
  }

  .card-body {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
}

.image-style {
  width: 3em;
}

.font-details {
  font-size: 3em;
  font-weight: 500;
  color: #2293d4f8;
}

.font-details-b {
  position: relative;
  margin-bottom: 25px;
  padding-bottom: 15px;
}
